import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import NetlifyForm from "react-netlify-form";
import Getdagcoins from "../components/get-dagcoins";
import Headertransparent from "../components/headertransparent";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
import "../components/contact.css";
import "../components/index.css";

function ContactPage({ data }) {
  var SeoMeta = data.prismicContact.data;

  var NextUid = data.prismicContact.alternate_languages[0].uid;

  if (data.prismicContact.lang === "en-us") {
    var NextUrl = "/et/" + NextUid;
  } else if (data.prismicContact.lang === "et-ee") {
    NextUrl = "/" + NextUid;
  } else {
    NextUrl = "wrong lang";
  }

  return (
    <Layout htmllang={data.prismicContact.lang}>
      <Headertransparent
        htmllang={data.prismicContact.lang}
        langswitcher={NextUrl}
      />

      <Container className="above fullwidth contact-page">
        <GatsbyImage
          alt="Dagcoin"
          image={data.homebg.childImageSharp.gatsbyImageData}
        />
        <div className="flex">
          <Row className="homevideo"></Row>
          <Row>
            <h1>{SeoMeta.page_title.text}</h1>
          </Row>
        </div>
      </Container>
      <Container className="formwrap">
        <PrismicRichText field={SeoMeta.content.richText} />
        <NetlifyForm name="Contact">
          {({ loading, error, success }) => (
            <div className="order-form">
              {loading && <div>Loading...</div>}
              {error && (
                <div>
                  {data.prismicContact.data.contact_form[0].error_message.text}
                </div>
              )}
              {success && (
                <div className="success-pop">
                  {
                    data.prismicContact.data.contact_form[0].success_message
                      .text
                  }
                </div>
              )}
              {!loading && !success && (
                <React.Fragment>
                  <div className="form-content">
                    <p>
                      <label>
                        <span>
                          {data.prismicContact.data.contact_form[0].name.text}
                        </span>
                        <input
                          className="textinput"
                          type="text"
                          name="Name"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        <span>
                          {data.prismicContact.data.contact_form[0].e_mail.text}
                        </span>
                        <input
                          className="textinput"
                          type="email"
                          name="email"
                          required
                        />
                      </label>
                    </p>
                    <p>
                      <label>
                        <span>
                          {
                            data.prismicContact.data.contact_form[0].message
                              .text
                          }
                        </span>
                        <textarea name="message" required />
                      </label>
                    </p>
                  </div>
                  <div className="form-footer">
                    <p>
                      <button type="submit" className="sendbtn">
                        <span>
                          {
                            data.prismicContact.data.contact_form[0].send_button
                              .text
                          }
                        </span>
                      </button>
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </NetlifyForm>
      </Container>
    </Layout>
  );
}

export const Head = ({ data }) => {
  return (
    <>
      <Seo
        // TODO: Add description
        pathname={data.prismicContact.uid}
        // TODO: Title should come from "seo_title" query
        title={data.prismicContact.data.page_title.text}
        // lang={data.prismicContact.lang}
      />
    </>
  );
};

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicContact(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        id
        lang
        type
        uid
      }
      lang
      uid
      data {
        contact_form {
          success_message {
            text
          }
          send_button {
            text
          }
          name {
            text
          }
          message {
            text
          }
          error_message {
            text
          }
          e_mail {
            text
          }
        }
        page_title {
          text
        }
        content {
          text
          richText
        }
      }
    }
    homebg: file(relativePath: { eq: "homebg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 99, layout: FULL_WIDTH)
      }
    }
  }
`;
export default ContactPage;
